// React 16 polyfills - https://reactjs.org/docs/javascript-environment-requirements.html
if (window && !window._babelPolyfill) {
	require('babel-polyfill');
}
// other polyfills
import 'console-polyfill';
import 'raf/polyfill';
import 'cross-fetch/polyfill';
import '@plecto/components/dist/index.css';
// globalThis used by imask, not supported in IE (obviously)
if (window && !window.globalThis) {
	window.globalThis = window;
}

if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		let el = this;

		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}
