import 'utils/polyfills';
import '../../pages/setup/setupSentry';
import addToNamespace from 'utils/addToNamespace';
import React from 'react';
import ReactDOM from 'react-dom';
import { AchievementShowcase } from '@plecto/components';
import { Translations } from 'utils/Translations';

const mapAchievementProps = achievement => ({
	id: achievement.id,
	typeId: achievement.type_id,
	iconId: achievement.icon_id,
	name: achievement.name,
	iconClass: achievement.icon_class,
	localizedDate: achievement.localized_date,
	description: achievement.description,
	active: achievement.active,
	color: achievement.color,
	shape: achievement.shape,
});

function achievementsShowcasesInit({ placeholderClass, dataAttribute }) {
	Translations.setLanguageFromTag();
	const showcases = document.getElementsByClassName(placeholderClass);
	for (let i = 0; i < showcases.length; i++) {
		const showcase = showcases[i];
		const achievements = JSON.parse(showcase.getAttribute(dataAttribute)).map(mapAchievementProps);
		ReactDOM.render(<AchievementShowcase showTooltip={true} achievements={achievements}/>, showcase);
	}
}

addToNamespace(achievementsShowcasesInit, 'AchievementsShowcases');
