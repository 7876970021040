/**
* stringTemplateFormat
* --------------------
*
* Replaces each kwarg in the template with the provided kwargs.
*
* Example usage:
*
* stringTemplateFormat('/admin/data-sources/{datasource}/', {'datasource': '12345'});
* >> '/admin/data-sources/12345/'
*
*/

function stringTemplateFormat(template, context) {
	template = String(template);
	Object.keys(context).forEach(kwarg => {
		template = template.replace(new RegExp('{' + kwarg + '}', 'g'), context[kwarg]);
	});
	return template;
}

export default stringTemplateFormat;
