/*
 * Exposes the provided [obj] with the provided [name] inside an object with the name [namespace] on the global window.
 *
 * Use this to add your App to window.Plecto.App so it can be initialized on a page.
 */

export default function addToNamespace(obj, name, namespace = 'Plecto') {
	if (!window.hasOwnProperty(namespace)) {
		window[namespace] = {};
	}
	if (window[namespace].hasOwnProperty(name)) {
		throw new Error('"' + name + '" is already part of the "' + namespace + '" namespace!');
	}
	window[namespace][name] = obj;
}
