import stringTemplateFormat from './string/stringTemplateFormat';
import { setLanguage } from '@plecto/components';

const Translations = {
	activeLanguage: 'en',
	availableLanguages: ['en', 'da'],

	strings: {
		'<NOT SET>': {  // used when a registration label is not set
			da: '<NOT SET>',
		},
		Edit: {
			da: 'Rediger',
		},
		Fields: {
			da: 'Felter',
		},
		Settings: {
			da: 'Indstillinger',
		},
		Filters: {
			da: 'Filtre',
		},
		'Add filter': {
			da: 'Tilføj filter',
		},
		'equal to': {
			da: 'svarende til',
		},
		'not equal to': {
			da: 'ikke lig med',
		},
		'greater than': {
			da: 'større end',
		},
		'less than': {
			da: 'mindre end',
		},
		'greater or equal to': {
			da: 'større eller lig med',
		},
		'less or equal to': {
			da: 'mindre eller lig med',
		},
		Label: {
			da: 'Betegnelse',
		},
		Employee: {
			da: 'Medarbejder',
		},
		Team: {
			da: 'Team',
		},
		Visibility: {
			da: 'Synlighed',
		},
		Actions: {
			da: 'Handlinger',
		},
		'Filter by this value': {
			da: 'Sorter efter denne værdi',
		},
		'View in new window': {
			da: 'Vis i nyt vindue',
		},
		'Total registrations': {
			da: 'Samlet registreringer',
		},
		'Not sorted': {
			da: 'Ikke sorteret',
		},
		'Sorted from highest to lowest': {
			da: 'Sorteret fra højest til lavest',
		},
		'Sorted from lowest to highest': {
			da: 'Sorteret fra lavest til højest',
		},
		'Sorted in alphabetical order': {
			da: 'Sorteret i alfabetisk rækkefølge',
		},
		'Sorted in reversed alphabetical order': {
			da: 'Sorteret i omvendt alfabetisk rækkefølge',
		},
		'Remove filter': {
			da: 'Fjern filter',
		},
		'No more registrations': {
			da: 'Ikke flere registreringer',
		},
		'Hide all fields': {
			da: 'Skjul alle felter',
		},
		'Clear filters and sorting': {
			da: 'Ryd filtrering og sortering',
		},
		'No items to display': {
			da: 'Intet at vise',
		},
		contains: {
			da: 'indeholder',
		},
		'starts with': {
			da: 'starter med',
		},
		'ends with': {
			da: 'slutter med',
		},
		'not contains': {
			da: 'indeholder ikke',
		},
		'not starts with': {
			da: 'starter ikke med',
		},
		'not ends with': {
			da: 'slutter ikke med',
		},
	},

	missingStrings: [],

	setLanguage: function(lang) {
		if (this.availableLanguages.indexOf(lang) >= 0) {
			this.activeLanguage = lang;
		} else {
			this.activeLanguage = 'en';
			if (process.env.NODE_ENV !== 'test') {
				console.warn(`Language "${lang}" is not supported!`);
			}
		}

		setLanguage(this.activeLanguage);
	},

	setLanguageFromTag: function() {
		this.setLanguage(document.documentElement.lang);
	},

	getTranslation: function(str, context) {
		context = context ? context : {};
		let template = str;
		if (this.strings.hasOwnProperty(str) && this.strings[str].hasOwnProperty(this.activeLanguage)) {
			template = this.strings[str][this.activeLanguage];
		} else if (this.activeLanguage === 'en') {
			// pass
		} else {
			if (this.missingStrings.indexOf(str) < 0) {
				this.missingStrings.push(str);
				if (process.env.NODE_ENV !== 'test') {
					console.warn(`Missing "${this.activeLanguage}" translation: ${str}`);
				}
			}
		}
		return stringTemplateFormat(template, context);
	},
};

const _t = function(str, context) {
	return Translations.getTranslation(str, context);
};

export { _t, Translations };
