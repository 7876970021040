import * as Sentry from '@sentry/react';

if (process.env.SENTRY_ENABLED) {
	try {
		const version = document.querySelector('meta[name="frontend-version"]').content;
	
		Sentry.init({
			dsn: 'https://4f3387ad5d3a4509b970801f8d078191@o27497.ingest.sentry.io/5689070',
			release: `plecto-frontend-react@${version}`,
			environment: process.env.SENTRY_ENVIRONMENT,
			ignoreErrors: [
				'StatusPage is not defined',
				'\'StatusPage\' is not defined',
				'Can\'t find variable: StatusPage',
			],
			denyUrls: [
				/service\.force\.com/i,
				/partners\.plecto\.com/i,
				/extensions\//i,
				/^chrome:\/\//i,
				/^chrome-extension:\/\//i,
				/^safari-extension:\/\//i,
				/^safari-web-extension:\/\//i,
				/embeddedservice/i,
				/connect\.facebook/i,
				/localhost/i,
				/127.0.0.1/i
			]
		});
	}
	catch {
		console.error('Failed to initialize Sentry');
	}
}

